/**
 * Used to automatically log-in from www.amblea.fr.
 */

import React from 'react'

import {useRouter} from 'next/router'

import {LoadingPage, useNewAuth} from '../components/stack/auth'
import {useProfile} from '../components/stack/profile'

const useAction = () => {
  const {isLoading, isAuthenticated} = useNewAuth()
  const profile = useProfile()

  if (isLoading) {
    return ['WAITING'] as const
  }

  if (!isAuthenticated) {
    return ['LOGIN'] as const
  }

  if (profile.type === 'INVITED') {
    return ['REDIRECT', '/account/unverified'] as const
  }

  if (profile.mts.length === 0 && profile.mfus.length === 0) {
    return ['REDIRECT', '/account/unlinked'] as const
  }

  if (profile.mfus.length) {
    return ['REDIRECT', '/es/dashboard'] as const
  }

  if (profile.mts.some(mtProfile => mtProfile.mt.type === 'DISPATCHER')) {
    return ['REDIRECT', process.env.AMBLER_BO_URL] as const
  }

  if (profile.mts.filter(mtProfile => mtProfile.mt.type !== 'DISPATCHER').length) {
    return ['REDIRECT', '/ts/dashboard'] as const
  }

  return ['REDIRECT', '/'] as const
}

function Page() {
  const router = useRouter()
  const {signIn} = useNewAuth()
  const [action, url] = useAction()

  React.useEffect(() => {
    if (action === 'LOGIN') {
      signIn()
      return
    }

    if (action === 'REDIRECT') {
      if (url.startsWith(process.env.AMBLER_STAGE.search('local:') ? 'http' : 'https')) {
        window.location.href = url
        return
      }
      router.replace(url)
    }
  }, [action, router, signIn, url])

  return (
    <>
      {action === 'WAITING' && <LoadingPage message="Connexion en cours..." />}
      {action === 'LOGIN' && <LoadingPage message="Veuillez vous connecter" />}
      {action === 'REDIRECT' && <LoadingPage message="Redirection en cours..." />}
    </>
  )
}

export default Page
